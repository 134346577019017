.react-datepicker-wrapper {
  width:  100% !important;
}
.custom-datepicker {
  border: 0px;
  width: 107px;
  /* Add any other styles you want */
}
.urdu-form {
    text-align: right;
  }
  
  .urdu-label {
    float: right;
    clear: right;
    margin-right: 15px;
    font-family: 'Jameel Noori Nastaleeq';
  }
  
  .urdu-input {
    float: right;
    direction: rtl;
    text-align: right;
    font-family: 'Jameel Noori Nastaleeq';
  }

  .underline {
    border-bottom: 1px solid rgb(218, 207, 207);
  }

  @media print {
    .print-table {
      width: 100%;
      /* Adjust other print styles */
    }
  }
  
  @font-face {
    font-family: 'Bombay Black';
    src: url('./BombayBlack.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Jameel Noori Nastaleeq';
    src: url('./JameelNooriNastaleeqRegular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }


  .font-tables {
    font-family: 'Jameel Noori Nastaleeq';
    font-size: 22px;
  }

  .font-tables-headings{
    font-family: 'Bombay Black';
    font-size: 22px;
  }



  /*  testing  */

  .th{
    border-top:1px solid white !important; border-left:1px solid white !important; border-right:1px solid white !important;
  }

  /* Style for the expanding textarea */
.expanding-textarea {
  overflow: hidden;
  resize: none;
  min-height: 1em; /* Set a minimum height */
}

/* Remove border and padding for the textarea to align with table cell */
.expanding-textarea {
  border: none;
  padding: 0;
  margin: 0;
}