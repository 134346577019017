
  
  /* Add more styles for other colors as needed */
.highlight {
    background: red !important;
    color: white;
}
.nohighlight {
    color:black;
}
.divMargin {
    margin-top: -18% !important;
}
.react-calendar__tile--now {
    background: #198754;
    color: white !important;
}
