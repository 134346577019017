.user-view-btn {
    display: flex;
    justify-content: center;
    padding: 4px;
  }
  
  .content .main-container-head .table .country {
    text-transform: capitalize
  }
  
  .content .main-container-head .table .public-address:hover {
    color: #0d6efd;
  }
  
  /*///////////////////////////////////////////////////////////////*/
  
  .user-view-btn {
    display: initial;
    justify-content: center;
  }
  
  .content .main-container-head .table .country {
    text-transform: capitalize
  }
  
  .content .main-container-head .table .public-address:hover {
    color: #45d8fc;
  }
  
  /* ========== Custom Styles View Data Modal =========== */
  
  .drop-modal {
    max-width: 1050px !important;
    top: 0% !important;
  }
  
  .modal.show .modal-dialog.drop-modal {
    transform: translate(0, 5%);
  }
  
  .drop-modal .drop-modal-title p {
    color: #000000 !important;
    font-size: 24px;
    font-weight: 500;
    text-align: center;
  }
  
  .drop-modal .drop-modal-line hr {
    border-top: 1px solid rgba(0, 0, 0, 0.4);
  }
  
  .drop-modal .drop-modal-body {
    padding: 45px 25px !important;
  }

  
  .border {
    border: 1px solid #ccc;
  }