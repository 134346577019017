
/* body {
  overflow: hidden; Hide the scrollbar */
/* } */

.vertical-center {
  min-height: 100vh; /* Ensure the container takes up at least the full viewport height */
  display: flex;
  align-items: center;
}

@font-face {
  font-family: 'Bombay Black';
  src: url('./pages/BombayBlack.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Jameel Noori Nastaleeq';
  src: url('./pages/JameelNooriNastaleeqRegular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Quicksand";
  src: url("./fonts/Quicksand-Medium.woff2") format("woff2"),
    url("./fonts/Quicksand-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Quicksand";
  src: url("./fonts/Quicksand-SemiBold.woff2") format("woff2"),
    url("./fonts/Quicksand-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Quicksand";
  src: url("./fonts/Quicksand-Bold.woff2") format("woff2"),
    url("./fonts/Quicksand-Medium.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("./fonts/OpenSans-Regular.woff2") format("woff2"),
    url("./fonts/OpenSans-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

.react-datepicker-wrapper {
  width: 150px
}

@font-face {
  font-family: "Open Sans";
  src: url("./fonts/OpenSans-SemiBold.woff2") format("woff2"),
    url("./fonts/OpenSans-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  /* font-family: "Open Sans", sans-serif; */
  font-family: 'Jameel Noori Nastaleeq';
}

html {
  scroll-behavior: smooth;
}

body,
textarea,
input,
div,
a {
  padding: 0;
  margin: 0;
  line-height: 1.5;
  font-family: 'Jameel Noori Nastaleeq';
}

a {
  display: inline-block;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3 {
  font-family: "Quicksand";
}

ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

img {
  max-width: 100%;
  height: auto;
}

.common-btn {
  background: #697ffb !important;
  box-shadow: 0px 8px 36px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  border: 0 !important;
  color: #fff !important;
  outline: 0 !important;
  padding: 14px 30px !important;
  cursor: pointer;
  min-width: 166px;
  margin: 0 auto;
  font-family: "Quicksand";
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  transition: all ease-in-out 0.2s;
}

.common-btn:hover {
  background: #e75ea1 !important;
}

.common-btn.cancel {
  background: #2A2C40 !important;
}

.common-btn.cancel:hover {
  background: #1d1e2c !important;
}

header .navbar {
  padding: 37px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

header .navbar .logo img {
  width: 135px;
}

header .navbar-nav {
  display: flex;
  flex-direction: row;
}

/* header .navbar .navbar-collapse{
  justify-content: center;
} */

header .navbar ul li a {
  padding: 12px 23px !important;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #2A2C40;
  border-bottom: 2px solid #D9D9D9;
  transition: all ease-in-out 0.2s;
  font-family: "Quicksand";
}

header .navbar ul li a:hover,
header .navbar ul li a.active {
  color: #FB69B1 !important;
  border-bottom: 2px solid #FB69B1;
}

header .navbar .profile {
  display: flex;
  align-items: center;
}

header .navbar .profile span {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #2A2C40;
  margin-right: 18px;
  font-family: "Quicksand";
}

header .navbar .profile img {
  border: 2px solid #FB69B1;
  box-shadow: 0px 8px 36px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
}



.wrapper {
  min-height: calc(100vh - 99px);
  display: flex;
  justify-content: center;
  padding: 50px 0 20px;
}


h1,
h2 {
  font-weight: 700;
  font-size: 48px;
  line-height: 1.2;
  color: #2a2c40;
  margin-bottom: 48px;
}

p {
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #535565;
}

form {
  background: #f8fafb;
  border: 1px solid rgba(42, 44, 64, 0.05);
  border-radius: 16px;
  width: 512px;
  max-width: 100%;
  margin: 0 auto;
  padding: 48px;
  text-align: center;
}

form.get-your-subdomain {
  width: 700px;
}

form.get-your-subdomain h1,
.card-information h1 {
  margin-bottom: 24px;
  font-size: 32px;
}

form.get-your-subdomain p,
.card-information p {
  margin-bottom: 32px;
}

form.get-your-subdomain h2 {
  font-size: 24px;
  margin: 32px 0;
}

form.get-your-subdomain .row {
  margin: 0 -12px;
}

form.get-your-subdomain .col-sm-4 {
  padding: 0 12px;
}

.plan {
  background: #FFFFFF;
  box-shadow: 0px 0px 26px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  padding: 20px 16px;
}

.plan h3 {
  font-size: 32px;
  line-height: 1.2;
  text-align: center;
  color: #FB69B1;
  margin-bottom: 10px;
  font-weight: bold;
}

.plan span {
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #2A2C40;
  display: block;
  margin-bottom: 6px;
}

form.get-your-subdomain .plan p {
  margin-bottom: 0;
}

.btn-wrap {
  display: flex;
  justify-content: space-between;
}

.btn-wrap .common-btn {
  margin: 0;
}

form .form-group #show_hide_password {
  background: #f5f6fd;
  border-radius: 14px;
  height: 56px !important;
  padding: 0 24px;
}

form input,
form select {
  background-color: #ffffff !important;
  box-shadow: 0px 0px 26px rgba(0, 0, 0, 0.06) !important;
  border-radius: 8px !important;
  border: none !important;
  padding: 17px 24px !important;
  font-family: "Open Sans";
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 22px !important;
  color: #2a2c40 !important;
  width: 100%;
}

form select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url('../public/images/arrow-down.png');
  background-repeat: no-repeat;
  background-position: 95% center;
  outline: 0;
}

form p {
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

form p a {
  color: #198754;
}

form .form-group .input-group-addon a {
  padding: 16px 0;
}

form input::-webkit-input-placeholder {
  /* Edge */
  font-family: 'Open Sans';
  color: #2A2C40 !important;
  font-weight: 400;
  font-size: 16px;
  opacity: 0.4 !important;
}

form input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-family: 'Open Sans';
  color: #2A2C40 !important;
  font-weight: 400;
  font-size: 16px;
  opacity: 0.4 !important;
}

form input::placeholder {
  font-family: 'Open Sans';
  color: #2A2C40 !important;
  font-weight: 400;
  font-size: 16px;
  opacity: 0.4 !important;
}

form .form-control:focus {
  border-color: unset;
  outline: 0;
}

.checkbox-wrap {
  position: relative;
}

.checkbox-wrap input {
  padding: 0;
  margin-bottom: 0;
  cursor: pointer;
  position: absolute;
  width: 15px;
  height: 15px;
  left: 12px;
  top: 4px;
  opacity: 0;
  z-index: 2;
}

.checkbox-wrap label {
  position: relative;
  padding-left: 28px;
  text-align: left;
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #535565;
}

.checkbox-wrap label a {
  color: #FB69B1;
}

.checkbox-wrap label:before {
  content: '';
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #FB69B1;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 6px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
  border-radius: 3px;
  position: absolute;
  left: 0;
  top: 4px;
}

.checkbox-wrap input:checked+label:before {
  background-color: #FB69B1;
}

.checkbox-wrap input:checked+label:after {
  content: '';
  display: block;
  position: absolute;
  top: 5px;
  left: 6px;
  width: 5px;
  height: 11px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.payment-modal .payment-successfull {
  display: none;
}

.payment-modal {
  text-align: center;
}

.payment-modal .modal-dialog {
  max-width: 640px;
}

.payment-modal .modal-content {
  border: 0;
}

.payment-modal .modal-body {
  padding: 48px;
}

.payment-modal h3 {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #2A2C40;
  margin-bottom: 24px;
}

.payment-modal p {
  max-width: 450px;
  margin: 0 auto 32px;
}

.payment-modal img {
  animation: rotation 2s infinite linear;
}

.history.admin-dashboard .row {
  justify-content: center;
}

.history h3 {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #2A2C40;
  margin-bottom: 32px;
}

.history .info-box {
  padding: 24px 20px 20px;
  background: #F8FAFB;
  border: 1px solid rgba(42, 44, 64, 0.05);
  border-radius: 16px;
  margin-bottom: 24px;
}

.history .pl-history .row {
  margin-bottom: 24px;
}

.history .info-box p {
  margin-bottom: 18px;
}

.history .info-box .wrap {
  display: flex;
  align-items: center;
}

.history .info-box .wrap span {
  font-family: 'Quicksand';
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #2A2C40;
  display: inline-block;
  margin-right: 10px;
}

.table-wrap {
  overflow: auto;
  border-radius: 16px;
}

.table-wrap table {
  width: 100%;
  background: #F8FAFB;
  border: 1px solid rgba(42, 44, 64, 0.05);
  min-width: 680px;
}

.table-wrap table th {
  background: #FB69B1;
  padding: 18px 24px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
}

.table-wrap table td {
  padding: 10px 20px;
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #2A2C40;
  border-bottom: 1px solid rgba(42, 44, 64, 0.05);
  height: 63px;
}

.table-wrap table td:first-child {
  font-weight: 600;
}

.table-wrap table td:last-child {
  font-size: 18px;
  font-weight: 600;
}

.admin-dashboard .table-wrap table td:last-child {
  font-size: 14px;
  line-height: 25px;
}

.table-wrap table td:last-child span {
  display: block;
  font-size: 14px;
  line-height: 20px;
}

.table-wrap table td.loss {
  color: #FF2323;
}

.table-wrap table td.profit {
  color: #00A237;
}

.steps-form {
  margin-top: -30px;
}

.steps-form .nav-tabs {
  max-width: 920px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  border: 0;
  position: relative;
  margin-bottom: 24px;
}

.steps-form .nav-tabs:before {
  position: absolute;
  content: '';
  height: 2px;
  width: 100%;
  background: rgba(42, 44, 64, 0.05);
  top: 11px;
  z-index: -1;
}

.steps-form .nav-tabs li {
  width: 48px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.steps-form .nav-tabs li a {
  border: 0 !important;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #D9D9D9;
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #FFFFFF;
  padding: 0 !important;
}

.steps-form .nav-tabs li a.active {
  background: #FB69B1;
  color: #fff;
}


.steps-form .tab-content h3 {
  font-size: 16px;
  line-height: 20px;
  color: #2A2C40;
  font-weight: 700;
  margin-bottom: 24px;
}

.steps-form .tab-content h4 {
  font-family: 'Quicksand';
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #2A2C40;
  margin-bottom: 15px;
}

.steps-form .tab-content form {
  padding: 32px;
  text-align: left;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

@media screen and (min-width:1200px) {
  .history.admin-dashboard .row {
    flex-grow: 1;
  }
}

@media screen and (max-width:767px) {
  header .navbar {
    padding: 25px 0;
  }

  header .navbar-nav {
    position: absolute;
    width: 100%;
    justify-content: center;
    bottom: -23px;
  }

  .steps-form {
    margin-top: 0;
  }
}

@media screen and (max-width:575px) {
  form {
    padding: 32px 24px;
  }

  .btn-wrap {
    display: flex;
    flex-direction: column-reverse;
  }

  .btn-wrap .common-btn {
    margin-bottom: 16px;
  }
}

@media print {
  .non-printable {
    display: none;
  }
  /* .print-table {
    border-collapse: collapse;
  } */

  .print-table thead {
  
    display: table-header-group;
  }

  .print-table tbody {
    display: table-row-group;
  }

  .print-table tr {
    page-break-inside: avoid;
  }

  .print-table th,
  .print-table td {
    /* border: 1px solid black; */
    padding: 10px;
  }

  .print-table th {
    background-color: #f2f2f2;
  }

  .print-table thead {
    padding-top: 10px;
    display: table-header-group;
  }

  .print-table th:first-child {
    position: sticky;
    top: 0;
    background-color: #f2f2f2;
  }
}